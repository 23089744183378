<template>
	<div class="card">
		<div class="card-header">
			<h4 class="card-header-title">{{ type | titleCase }} Bookings</h4>
			<div class="d-flex justify-content-between">
				<div class="input-group input-group-flush d-flex flex-row-reverse">
					<input
						v-model.trim="search"
						class="form-control list-search"
						type="search"
            @keyup="applySearch"
						placeholder="Search"
						/>
					<span class="input-group-text border-0">
						<i class="fe fe-search"></i>
					</span>
				</div>
			</div>
      <div class="col-auto">
        <v-datepicker style="width: 100%" v-model="filter.startEndDatesFilterArray" placeholder="Filter by date" range></v-datepicker>
      </div>
		</div>
		<div class="card-body p-0">
			<b-table
				striped
				hover
				selectable
				show-empty
				:items="filteredBookings"
				:fields="fields"
				:per-page="perPage"
				:current-page="currentPage"
				:busy="loadingBookings"
				@row-clicked="viewBooking"
				>
				<template #table-busy>
					<div
						class="d-flex flex-column justify-content-center text-secondary my-2 align-items-center"
						>
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>
				<template #empty>
					<h4 class="text-center">There are no {{ type }} bookings</h4>
				</template>
				<template #cell(route)="data">
        <route-description
						:max-width="250"
						:pickup="data.item.route.pickup"
						:destination="data.item.route.destination"
						>
					</route-description>
				</template>
				<template #cell(route_code)="data">
					<router-link
						class="sh-page-link"
						:to="{
							name: 'ShowRoute',
							params: { routeId: data?.item?.route?.id },
						}"
						>{{ data?.item?.route?.route_code }}
					</router-link>
				</template>
				<template #cell(start_date)="data">
					{{
						data?.item?.trip
							? `${data?.item?.trip?.start_trip }`
							: `${data?.item?.itinerary?.trip_time}, ${data?.item?.start_date || 'N/A'}`
					}}
				</template>
				<template #cell(end_date)="data">
          <span v-if="type === 'cancelled'">
						{{ data?.item?.created_at  }}
					</span>
          <span v-else>
						{{ data?.item?.end_date || 'N/A' }}
					</span>
				</template>

				<template #cell(amount)="data">
					<span
						>₦{{
							(isActive ? data?.item.cost : data?.item?.unit_cost) || 0 | money
						}}</span
						>
				</template>

				<template #cell(payment_source)="data">
					<span>
            {{
                data?.item?.payment_source
            }} <br/>  {{
            data?.item?.instant_payment_provider === 'corporate_pay' ? `(${data?.item?.instant_payment_provider})`  : ''}}
        </span>
				</template>

        <template #cell(route_type)="data">
        <span>
					{{
                data?.item?.route?.visibility
            }}
        </span>
        <br/>
        <span>
            {{
                data?.item?.route?.is_exclusive ? "exclusive" : "shared"
            }}
        </span>
				</template>
			</b-table>

			<div class="card-footer" v-if="filteredBookings?.length">
				<b-pagination
					v-model="currentPage"
					:total-rows="filteredBookings?.length"
					:per-page="perPage"
					></b-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import moment from 'moment'
export default {
  components: { RouteDescription },
  props: {
    userId: [String, Number],
    type: {
      type: String,
      validator: (value) => ['active', 'past', 'cancelled'].includes(value)
    }
  },
  data () {
    return {
      loadingBookings: true,
      errorLoadingBookings: false,
      filter: {
        startEndDatesFilterArray: []
      },
      bookings: [],
      search: '',
      fields: [
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'route_code',
          label: 'Route Code'
        },
        {
          key: 'start_date',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'end_date',
          label: `${this.type == 'cancelled' ? 'Date Cancelled' : 'End Date'}`,
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'payment_source',
          label: 'Payment Source'
        },
        {
          key: 'route_type',
          label: 'Route Type'
        }
      ],
      perPage: 10,
      currentPage: 1
    }
  },
  created () {
    this.fetchBookings()
  },
  computed: {
    filteredBookings(){
      // return this.bookings.data
      return this.bookings
    },
    isActive () {
      return this.type === 'active'
    },
    bookingUrl(){
       if(this.type === 'active'){
        return `/v1/users/${this.userId}/routes`
       }

       if(this.type !== 'active'){
        return `/v1/users/${this.userId}/user-route-schedules`
       }
    },
    dateRange()
    {
      return this.filter.startEndDatesFilterArray.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
    },
    dateFilterValid()
    {
      return (
        this.filter.startEndDatesFilterArray.length &&
        this.filter.startEndDatesFilterArray.filter((date) => !!date).length > 0
      )
    }
  },
  methods: {
    fetchBookings () {
      const params = {
        status: this.type === 'past' ? 'completed' : this.type,
      }
      if(this.search){
        params['search'] = this.search
      }
       if(this.dateRange[0] || this.dateRange[1]){
        params['from'] = this.dateRange[0]
        params['to'] = this.dateRange[1]
       }
      this.loadingBookings = true
      this.errorLoadingBookings = false
      const url = this.bookingUrl
      this.axios
        .get(url, {params})
        .then((res) => {
          this.bookings = res.data
        })
        .catch(() => (this.errorLoadingBookings = true))
        .finally(() => (this.loadingBookings = false))
    },

    viewBooking (booking) {
      if (this.$route.name === 'UserActiveBookings') {
        this.$router.push({
          name: 'ActiveBookingTicket',
          params: {
            bookingId:
              this.type === 'active' ? booking.id : booking.user_route_id,
            scheduleId: booking.id
          }
        })
      }

      if (this.$route.name === 'UserPastBookings') {
        this.$router.push({
          name: 'PastBookingTicket',
          params: {
            bookingId:
              this.type === 'active' ? booking.id : booking.user_route_id,
            scheduleId: booking.id
          }
        })
      }

      if (this.$route.name === 'UserCancelledBookings') {
        this.$router.push({
          name: 'CancelledBookingTicket',
          params: {
            bookingId:
              this.type === 'cancelled' ? booking.id : booking.user_route_id,
            scheduleId: booking.id
          }
        })
      }
    },
    applySearch(event)
    {
      if (event.key === 'Enter' || event.keyCode === 13) {
        const q = { ...this.$route.query, q: this.search }
        if (!_.isEqual(q, this.$route.query)) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: q
          })
        }

        this.refreshTable(true)
      } else if (!this.search) {
        this.refreshTable(false)
      }
    },
    refreshTable(searchEnabled = false)
    {
      if (!searchEnabled) {
        this.search = ''
      }
      if (this.currentPage === 1) {
        this.fetchBookings()
      } else {
        this.currentPage = 1
      }
    },
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('h:mm A, YYYY-MM-DD')
    }
  },
  watch: {
    dateFilterValid()
    {
      this.fetchBookings()
    },
    dateRange(newValue, oldValue)
    {
      if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
        const q = { ...this.$route.query, from: newValue[0], to: newValue[1] }
        if (!_.isEqual(q, this.$route.query)) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: q
          })
        }
        this.fetchBookings()
      }
    }
  },
}
</script>
